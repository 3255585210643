import { PropsWithChildren, ReactNode } from "react";

import cx from "classix";

type AppBarProps = PropsWithChildren<{
  childrenWrapperClassname?: string;
  suffixes?: {
    key: string;
    element: ReactNode;
  }[];
}>;

export const AppBar = ({ children, childrenWrapperClassname, suffixes }: AppBarProps) => {
  return (
    <header className="sticky top-0 w-full max-w-3xl flex py-1 justify-between border-b border-b-darkTertiary bg-black box-content">
      <div className={cx("flex items-center gap-3 pl-4", childrenWrapperClassname)}>{children}</div>
      {suffixes && (
        <div className="flex items-center gap-6 pr-4">
          {suffixes.map(({ key, element }) => (
            <span className="flex" key={key}>
              {element}
            </span>
          ))}
        </div>
      )}
    </header>
  );
};
